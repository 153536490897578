<template>
  <v-container fluid>
    <v-overlay absolute v-model="overlay">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <template v-if="!overlay">
      <v-card-title class="text-h4 font-weight-bold">
        <div class="d-flex d-inline-flex align-center align-self-auto">
          <v-avatar size="200">
            <img
              :src="
                getters_user_role.avatar_url
                  ? getters_user_role.avatar_url
                  : require('@/assets/download.jpg')
              "
              alt="alt"
            />
          </v-avatar>
          <v-container grid-list-xs>
            <span>
              {{ getters_user_role.title_name }}
            </span>
          </v-container>
        </div>
      </v-card-title>
      <v-card flat v-if="user_role">
        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                class="rounded-0"
                color="primary"
                cache-items
                outlined
                :menu-props="{ top: false, offsetY: true }"
                item-value="id"
                item-text="title"
                :items="getters_role"
                v-model="user_role"
                label="Roles"
                multiple
              >
                <template v-slot:selection="{ item }">
                  <v-chip color="primary">
                    <span>{{ item.title }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-footer color="transparent" app>
            <v-btn
              color="success"
              @click="updateUserRoleBtn"
              :loading="loading"
              :disabled="loading"
            >
              save changes
            </v-btn>
          </v-footer>
        </v-card-actions>
      </v-card>

      <!--  <v-alert type="info" text prominent v-else-if="user_role.length == 0">
        {{ infoDetails }}
      </v-alert> -->

      <v-alert v-else text prominent type="error" icon="mdi-close-circle">
        {{ errorDetails }}
      </v-alert>
    </template>

    <Response v-if="actionResponse" style="bottom: 20px">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import { defineComponent, getCurrentInstance, inject, provide, reactive, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Api from "@/apis/Api";
  import Response from "@/components/ActionResponse/Response";

  export default defineComponent({
    components: { Response },
    setup() {
      const vm = getCurrentInstance()
      const { getRole, userRole, signOut } = useActions([
        "getRole",
        "userRole",
        "signOut",
      ]);
      const { getters_role, getters_user_role } = useGetters([
        "getters_role",
        "getters_user_role",
      ]);
      const staff_id = inject("staff_id");

      const data = reactive({
        overlay: true,
        user_role: [],
        actionResponse: false,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        color: "",
        loading: false,
        errorDetails: "",
        infoDetails: "",
      });

      const {
        overlay,
        user_role,
        actionResponse,
        msgHeader,
        msgBody,
        msgIcon,
        color,
        loading,
        errorDetails,
        infoDetails,
      } = toRefs(data);

      Promise.all([
        userRole(Number(staff_id.value)).catch((e) => {
          msgHeader.value = "Error";
          msgIcon.value = "mdi-close-circle";
          color.value = "error";
          switch (e.response.status) {
            case 423:
              msgBody.value = e.response.data.message;
              signOut().then(() => {
               vm.proxy.$router.replace({
                  name: "Login",
                });
              });
              break;
            case 403:
              msgBody.value = e.response.data.message;
              break;
            default:
              msgBody.value = "Try Again Later Or Call The System Administrator";
              break;
          }
        }),
        getRole(),
      ])
        .then(() => {
          user_role.value = [...getters_user_role.value.roles].map(
            (item) => item.id
          );

          if (user_role.value.length === 0)
            infoDetails.value =
              "No Role(s) Has Been Assigned Yet. Contact System Administrator Now";
          overlay.value = false;
        })
        .catch((err1, err2) => {
          console.log(err1, err2);
          overlay.value = false;
          errorDetails.value =
            "Try Closing And Re-opening This User To View The Details";
        });

      provide("color", color);

      const updateUserRoleBtn = () => {
        actionResponse.value = false;
        loading.value = true;
        Api()
          .post("/update_user_role", {
            staff_id: staff_id.value,
            role: user_role.value,
          })
          .then(() => {
            actionResponse.value = true;
            loading.value = false;
            color.value = "success";
            msgHeader.value = "Success";
            msgIcon.value = "mdi-check";
            msgBody.value = "Staff's Role(s) Have Been Updated";
          })
          .catch(() => {
            loading.value = false;
            user_role.value = getters_user_role.value.roles.map(
              (item) => item.id
            );
            actionResponse.value = true;
            color.value = "error";
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            msgBody.value = "Try Again Later Or Report To System Administrator";
          });
      };

      return {
        staff_id,
        ...toRefs(data),
        getters_role,
        user_role,
        updateUserRoleBtn,
        getters_user_role,
      };
    },
  });
</script>
